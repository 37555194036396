import React from "react";
import {
  ChallengeDetailsBadgeFragment,
  ChallengeDetailsPageFragment,
  useGetChallengeProgressByIdQuery,
} from "services/graphql";
import { Calendar, Trophy, CheckCircle } from "app/components/Icon/CustomIcons";
import { formatDateToLocaleString } from "helpers/calendarUtils";
import { ReactSVG } from "react-svg";
import { H2, H3, H4, P1, P2 } from "../Typography";
import { ChallengeProgressCard } from "../ChallengeProgressCard";
import styles from "./styles.module.scss";
import { ChallengeProgressCardSkeleton } from "../ChallengeProgressCard/ChallengeProgressCardSkeleton";
import { Leaderboard } from "./Leaderboard";

interface ChallengeDetailsPageProps {
  challenge: ChallengeDetailsPageFragment;
}

function ChallengeProgressCardWrapper({
  challenge,
}: ChallengeDetailsPageProps) {
  const {
    loading,
    data: challengeProgressData,
  } = useGetChallengeProgressByIdQuery({
    variables: {
      challengeByIdId: challenge.id,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <ChallengeProgressCardSkeleton />;
  }

  if (!challengeProgressData?.challengeById) {
    return null;
  }

  return (
    <ChallengeProgressCard challenge={challengeProgressData?.challengeById} />
  );
}

export function ChallengesDetails({ challenge }: ChallengeDetailsPageProps) {
  const description = challenge.description?.replace(/\\n/g, "\n");

  const renderBadgeItem = (badge: ChallengeDetailsBadgeFragment) => (
    <div key={badge.id} className={styles.rewardItem}>
      {badge.activeImageUrl ? (
        <ReactSVG src={badge.activeImageUrl} className={styles.rewardImage} />
      ) : (
        <div className={styles.defaultCircle} />
      )}
      <div>
        <H4 className={styles.rewardText}>{badge.name}</H4>
        <P2 className={styles.rewardDescription}>{badge.description}</P2>
      </div>
    </div>
  );

  return (
    <div className={styles.scrollView}>
      <div className={styles.scrollViewContent}>
        <div>
          <img
            src={challenge.thumbnailImageUrl ?? ""}
            className={styles.thumbnail}
            alt={challenge.title}
          />

          <div className={styles.content}>
            <H2 className={styles.titleCentered}>{challenge.title}</H2>
            <P1 className={styles.subtitleCentered}>
              {challenge.displayText?.subtitleHeader}
            </P1>

            <ChallengeProgressCardWrapper challenge={challenge} />

            <div className={styles.infoContainer}>
              <div className={styles.infoRow}>
                <Calendar width={20} height={20} color="black" />
                <P2 className={styles.infoText}>
                  {challenge.startTime &&
                    formatDateToLocaleString(challenge.startTime)}{" "}
                  -{" "}
                  {challenge.endTime &&
                    formatDateToLocaleString(challenge.endTime)}
                </P2>
              </div>
              {challenge.displayText?.goalHeader && (
                <div className={styles.infoRow}>
                  <CheckCircle width={20} height={20} />
                  <P2 className={styles.infoText}>
                    {challenge.displayText.goalHeader}
                  </P2>
                </div>
              )}
              {challenge.displayText?.earnedByHeader && (
                <div className={styles.infoRow}>
                  <Trophy width={20} height={20} color="black" />
                  <P2 className={styles.infoText}>
                    {challenge.displayText.earnedByHeader}
                  </P2>
                </div>
              )}
            </div>

            {description && (
              <div className={styles.sectionContainer}>
                <H3 className={styles.sectionTitle}>Challenge Details</H3>
                <P2 className={styles.description}>{description}</P2>
              </div>
            )}

            {challenge.badges.length > 0 && (
              <div className={styles.sectionContainer}>
                <H3 className={styles.sectionTitle}>Reward Tiers</H3>
                <div>
                  {challenge.badges
                    .map(badge => badge.badge)
                    .sort((a, b) => b.amountToEarn - a.amountToEarn)
                    .map(renderBadgeItem)}
                </div>
              </div>
            )}
          </div>

          <Leaderboard
            challengeId={challenge.id}
            achievementTriggerType={challenge.achievement?.triggerType}
          />
        </div>
      </div>
    </div>
  );
}
