import React from "react";
import { H5, P2 } from "app/components/Typography";
import { ReactSVG } from "react-svg";
import { ProgressBar } from "app/components/ProgressBar";
import { ChallengeCardFragment } from "services/graphql";
import classNames from "classnames";
import colors from "theme/colors";
import { getBadgeUnit, getDaysLeft, getProgressPercentage } from "./helpers";
import styles from "./styles.module.scss";

interface ChallengeProgressCardProps {
  challenge: ChallengeCardFragment;
  onClick?: () => void;
}

export function ChallengeProgressCard({
  challenge,
  onClick,
}: ChallengeProgressCardProps) {
  const { title, endTime, userProgress, achievement } = challenge;
  const { nextBadge, currentBadge, currentBadgeProgress = 0 } =
    userProgress ?? {};

  const isCompleted =
    userProgress && (nextBadge === null || nextBadge === undefined);
  const achievementTriggerType = achievement?.triggerType;
  const firstBadge = challenge.badges?.[0]?.badge;
  const lastBadge = challenge.badges?.[challenge.badges.length - 1]?.badge;

  const { amountToEarn: nextBadgeAmount } = nextBadge ?? {};
  const { amountToEarn: lastBadgeAmount } = lastBadge ?? {};
  const { amountToEarn: firstBadgeAmount } = firstBadge ?? {};

  const amountToEarn =
    nextBadgeAmount ?? (isCompleted ? lastBadgeAmount : firstBadgeAmount) ?? 0;

  const nextBadgeName = nextBadge?.name ?? "";
  const currentBadgeImage =
    currentBadge?.activeImageUrl ?? lastBadge?.inactiveImageUrl ?? "";
  const badgeUnit = getBadgeUnit(achievementTriggerType);
  const daysLeft = getDaysLeft(endTime);

  let progressText = `${currentBadgeProgress} / ${amountToEarn} ${badgeUnit}`;
  if (isCompleted && lastBadge?.name) {
    progressText = `${progressText} earned ${lastBadge.name}`;
  } else if (nextBadgeName) {
    progressText = `${progressText} to ${nextBadgeName}`;
  }

  const progress = getProgressPercentage(
    isCompleted,
    currentBadgeProgress,
    amountToEarn
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <div
        className={classNames(styles.card, {
          [styles.completed]: isCompleted,
          [styles.clickableCard]: !!onClick,
        })}
        onClick={handleClick}
      >
        <div className={styles.content}>
          <H5 color={isCompleted ? colors.white : colors.black} mb={0}>
            {title}
          </H5>
          <div className={styles.rowContent}>
            {currentBadgeImage && (
              <ReactSVG className={styles.badgeImage} src={currentBadgeImage} />
            )}
            <div className={styles.progressContainer}>
              <P2 color={isCompleted ? colors.white : colors.black} mb={0}>
                {progressText}
              </P2>
              <ProgressBar
                className={styles.progressBar}
                color={isCompleted ? "bulbaGreen" : "primary"}
                percent={progress * 100}
              />
              <P2 color={isCompleted ? colors.bulbaGreen : colors.black}>
                {daysLeft} day{daysLeft !== 1 ? "s" : ""} left
              </P2>
            </div>
            {nextBadge?.activeImageUrl && (
              <ReactSVG
                className={styles.nextBadge}
                src={nextBadge.activeImageUrl}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
