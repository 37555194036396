import React from "react";
import { Link } from "react-router-dom";
import { H3, H5, P1 } from "app/components/Typography";
import { Avatar } from "app/components/Avatar";
import {
  ChallengesParticipantLeaderboardFragment,
  useGetChallengeLeaderboardQuery,
} from "services/graphql";
import { LeaderboardLoading } from "./LeaderboardLoading";
import { getBadgeUnit } from "../../ChallengeProgressCard/helpers";
import styles from "./styles.module.scss";

interface LeaderboardProps {
  challengeId: string;
  achievementTriggerType?: string;
}

export function Leaderboard({
  challengeId,
  achievementTriggerType,
}: LeaderboardProps) {
  const {
    data: challengeLeaderboardData,
    loading,
  } = useGetChallengeLeaderboardQuery({
    variables: {
      challengeByIdId: challengeId,
    },
    fetchPolicy: "network-only",
  });

  const participantLeaderboard =
    challengeLeaderboardData?.challengeById?.participantLeaderboard;

  const renderParticipantItem = (
    item: ChallengesParticipantLeaderboardFragment,
    index: number
  ) => (
    <Link
      key={`${item.user.id}-${index}`}
      to={item.user.username ? `/@${item.user.username}` : "#"}
      className={styles.participantItem}
    >
      <div className={styles.participantNameContainer}>
        <div className={styles.rankContainer}>
          <P1 className={styles.participantText}>{index + 1}.</P1>
        </div>
        <Avatar src={item.user.photoURL} width="24px" height="24px" />
        <P1 className={styles.participantText}>@{item.user.username}</P1>
      </div>
      <P1 className={styles.participantGoal}>
        {`${item.progressAmount} ${getBadgeUnit(achievementTriggerType)}`}
      </P1>
    </Link>
  );

  if (loading) {
    return <LeaderboardLoading />;
  }

  if (!participantLeaderboard?.length) {
    return null;
  }

  return (
    <div className={styles.sectionContainer}>
      <H3 className={styles.sectionTitle}>Top 10</H3>
      <div>
        <div className={styles.participantHeader}>
          <H5 className={`${styles.participantText} ${styles.headerText}`}>
            Dancer
          </H5>
          <H5 className={`${styles.participantGoal} ${styles.headerText}`}>
            {getBadgeUnit(achievementTriggerType)}
          </H5>
        </div>
        {participantLeaderboard.map(renderParticipantItem)}
      </div>
    </div>
  );
}
