import { isMobile } from "react-device-detect";

export const SHARE_SITES = {
  GOOGLE: "Google",
  ICAL: "iCal",
  OUTLOOK: "Outlook",
};

export const formatDate = date => date && date.replace("+00:00", "Z");

export function formatDateToLocaleString(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });
}

export const isInternetExplorer = () =>
  /MSIE/.test(window.navigator.userAgent) ||
  /Trident/.test(window.navigator.userAgent);

const googleShareUrl = ({
  description,
  endDatetime,
  location,
  startDatetime,
  timezone,
  title,
}) =>
  `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startDatetime}/${endDatetime}${timezone &&
    `&ctz=${timezone}`}&location=${location}&text=${title}&details=${description}`;

const buildShareFile = ({
  description = "",
  endDatetime,
  location = "",
  startDatetime,
  timezone = "",
  title = "",
}) => {
  const content = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "BEGIN:VEVENT",
    `URL:${document.URL}`,
    "METHOD:PUBLISH",
    timezone === ""
      ? `DTSTART:${startDatetime}`
      : `DTSTART;TZID=${timezone}:${startDatetime}`,
    timezone === ""
      ? `DTEND:${endDatetime}`
      : `DTEND;TZID=${timezone}:${endDatetime}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${description}`,
    `LOCATION:${location}`,
    "END:VEVENT",
    "END:VCALENDAR",
  ].join("\n");

  return isMobile
    ? encodeURI(`data:text/calendar;charset=utf8,${content}`)
    : content;
};

export const buildShareUrl = (
  {
    description = "",
    endDatetime,
    location = "",
    startDatetime,
    timezone = "",
    title = "",
  },
  type
) => {
  const encodeURI = type !== SHARE_SITES.ICAL && type !== SHARE_SITES.OUTLOOK;

  const data = {
    description: encodeURI ? encodeURIComponent(description) : description,
    endDatetime: formatDate(endDatetime),
    location: encodeURI ? encodeURIComponent(location) : location,
    startDatetime: formatDate(startDatetime),
    timezone,
    title: encodeURI ? encodeURIComponent(title) : title,
  };

  switch (type) {
    case SHARE_SITES.GOOGLE:
      return googleShareUrl(data);
    default:
      return buildShareFile(data);
  }
};
