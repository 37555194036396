import React from "react";
import { SkeletonText, SkeletonTextLoader } from "app/components/SkeletonText";
import styles from "./styles.module.scss";

export function LeaderboardLoading() {
  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionTitle}>
        <SkeletonText width="200px" height="24px">
          <SkeletonTextLoader width="100%" height="100%" />
        </SkeletonText>
      </div>
      <div className={styles.participantHeader}>
        <SkeletonText width="120px" height="20px">
          <SkeletonTextLoader width="100%" height="100%" />
        </SkeletonText>
        <SkeletonText width="80px" height="20px">
          <SkeletonTextLoader width="100%" height="100%" />
        </SkeletonText>
      </div>
      <div className={styles.leaderboardLoading}>
        {[...Array(10)].map((_, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`participant-loading-${i}`}
            className={styles.participantItem}
          >
            <div className={styles.participantNameContainer}>
              <div className={styles.rankContainer}>
                <SkeletonText width="20px" height="20px">
                  <SkeletonTextLoader width="100%" height="100%" />
                </SkeletonText>
              </div>
              <SkeletonText
                width="24px"
                height="24px"
                style={{ borderRadius: "50%" }}
              >
                <SkeletonTextLoader width="100%" height="100%" />
              </SkeletonText>
              <SkeletonText width="120px" height="20px">
                <SkeletonTextLoader width="100%" height="100%" />
              </SkeletonText>
            </div>
            <SkeletonText width="80px" height="20px">
              <SkeletonTextLoader width="100%" height="100%" />
            </SkeletonText>
          </div>
        ))}
      </div>
    </div>
  );
}
